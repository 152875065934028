
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import '~@/assets/scss/blocks/business-trip.scss';

.transport-type {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
}
.transport-type__item {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: rgba(16, 24, 40, 0.05);
  svg path {
    fill: rgba(16, 24, 40, 0.4);
  }
}
.transport-type__item:not(:last-child) {
  margin-right: 3px;
}

.transport-type__item:first-child {
  border-radius: 8px 0 0 8px;
}
.transport-type__item:last-child {
  border-radius: 0 8px 8px 0;
}
.transport-type__item--active svg path {
  fill: $color-primary-1-day;
}

/* switcher */
.way-block {
  position: relative;
}
.way-block__switcher {
  position: absolute;
  top: 50%;
  left: calc(50% - 80px);
  z-index: 6;
  width: 18px;
  height: 14px;
  // background-image: url('~@/assets/icons/switcher.svg');
  background-repeat: no-repeat;
  background-position: center;
  transform: translateY(-50%);
}
.way-block__switcher:hover {
  cursor: pointer;
}
