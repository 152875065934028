
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

@import '~@/assets/scss/blocks/business-trip.scss';
$bg-gray: #f5f5f5;
$gray: #999;
$gray-light: #ddd;
$gray-dark: #666;

::v-deep .esmp-input-element,
::v-deep .esmp-select-head {
  background-color: rgba(16, 24, 40, 0.05);
}

::v-deep .esmp-datepicker-dropdown {
  z-index: 100;
}

/***  business-trip top ***/
.business-trip__top {
  padding: 20px;
  margin: 0 0 43px;
  background: $bg-gray;
  border: 1px solid $gray-light;
  border-radius: 8px;
}

.business-trip__top-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.business-trip__top-icon {
  margin: 0 14px;
}

.business-trip__top-text {
  font-size: 24px;
  color: $black;
}

/*** business-trip__common ***/
.business-trip__common-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px;

  & + & {
    margin-top: 24px;
  }
}

.business-trip__common-cell {
  width: calc(50% - 10px);
}

.business-trip__common-cell:first-child:last-child {
  width: 100%;
}

/*** business-trip__next ***/
.business-trip__next {
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 100px;
  text-align: right;

  .base-button {
    display: inline-block;
  }
}

/*** business-trip__result ***/
.business-trip__result {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 200px;
}

.business-trip__result-inner {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 300px;
  min-height: 150px;
  padding: 25px;
  text-align: left;
  border: 1px solid $gray-light;
}

.business-trip__result-name {
  font-size: 16px;
  color: $gray-dark;
  letter-spacing: 0;
  &:not(:last-child) {
    margin-bottom: 9px;
  }
}

.business-trip__result-value {
  font-size: 24px;
  color: #101828;
  letter-spacing: 0;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.business-trip__result-actions {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: -20px;
}

.business-trip__result-actions ::v-deep .esmp-button {
  margin-left: 20px;
}

.business-trip__search .bus__input {
  font-size: 16px;
  background-color: $bg-gray;

  &::placeholder {
    font-size: 16px;
    color: $gray;
  }
}

.business-trip__footnote ::v-deep .esmp-link {
  font-size: 16px;
}
.business-trip__error,
::v-deep .business-trip__error {
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  line-height: 16px;
  word-break: break-word;
  font-feature-settings: "tnum", "lnum";
  padding: 0 12px;
  color: rgba(255, 12, 12, 0.8);
 }
