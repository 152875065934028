
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

$gray-light: #ddd;
/* stepper */
.stepper {
  width: 100%;
  font-weight: 300;
}
.stepper-head {
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  counter-reset: stepper;
  border-bottom: 3px solid $gray-light;
}
.stepper-head__item {
  flex-grow: 1;
  padding-bottom: 23px;
  font-size: 20px;
  color: #101828;
  text-align: center;
  letter-spacing: 0;
}
.stepper-head__item::before {
  content: counter(stepper) '. ';
  counter-increment: stepper;
}
.stepper-head__progress {
  position: absolute;
  bottom: -3px;
  left: 0;
  height: 3px;
  background: $color-primary-1-day;
}
.stepper-body__item {
  padding-top: 40px;
}
